<script setup>
import { computed, defineProps } from "vue"
import { useStore, formatPrice } from "./../../utils"

const store = useStore()

const props = defineProps({
  price: [Number, Object],
  compareAtPrice: [Number, Object],
  quantity: {
    type: Number,
    default: 1
  }
})

const getPriceAsCents = computed(() => {
  return (props.price && props.price.cents) || props.price
})

const getCompareAtPriceAsCents = computed(() => {
  return (
    (props.compareAtPrice && props.compareAtPrice.cents) || props.compareAtPrice
  )
})

const getDefaultPrice = computed(() => {
  // If there is a 'compareAtPrice', that should be the 'default' price against which the adjusted price is compared; otherwise just use the price.
  if (
    getCompareAtPriceAsCents.value &&
    getCompareAtPriceAsCents.value > getPriceAsCents.value
  ) {
    return formatPrice(
      getCompareAtPriceAsCents.value,
      store.state.locale,
      props.quantity
    )
  } else {
    return formatPrice(
      getPriceAsCents.value,
      store.state.locale,
      props.quantity
    )
  }
})

const getAdjustedPrice = computed(() => {
  return formatPrice(getPriceAsCents.value, store.state.locale, props.quantity)
})
</script>

<template>
  <div class="price-renderer">
    <div class="price-renderer__content">
      <span
        :class="{
          'price-renderer__default-price--strikethrough': props.compareAtPrice
        }"
        class="price-renderer__default-price"
      >
        {{ getDefaultPrice }}
      </span>
      <span v-if="props.compareAtPrice" class="text--clr-purple_dark">
        {{ getAdjustedPrice }}
      </span>
    </div>
  </div>
</template>

<style lang="scss">
.price-renderer {
  &__content {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: getcolour(bleach_black);
  }

  &__default-price {
    position: relative;
    margin-right: 0.2em;
    &--strikethrough {
      color: getcolour(bleach_grey_80);
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1.25px;
        top: 50%;
        left: calc(50% - 0.2em);
        transform-origin: center;
        transform: translate(-50%, 0) rotate(-8deg);
        background-color: getcolour(bleach_grey_50);
      }
    }
  }
}
</style>
